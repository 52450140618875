<template>
  <div class="gallery mx-md">
    <TimeFrame type="gallery" :timeFrame="galleryTimeFrame" @inquireClicked="setMode" />
  </div>
</template>

<script setup>
import { onMounted, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import TimeFrame from '@/components/TimeFrame';

const emit = defineEmits(['set-mode']);
const store = useStore();

//Lifecycle Hooks
onMounted(() => {
  getProjects();
});

//Methods
const getProjects = async () => {
  await store.dispatch('getProjects');
};
const setMode = () => {
  emit('set-mode', 'contact');
};

//Computed
const allProjects = computed(() => {
  return store.state.projects || [];
});
const galleryTimeFrame = computed(() => {
  let timeFrame = [];
  if(allProjects.value.length > 0){
    allProjects.value.forEach(project => {
      if(project.hidden != 'true' && project.type == 'gallery'){
        const hasOriginDateIndex = timeFrame.findIndex(tp => tp.date == new Date(project.origin_date).getFullYear());
        if(hasOriginDateIndex < 0){
          timeFrame.push({date: new Date(project.origin_date).getFullYear(), projects: [project]});
        } else {
          timeFrame[hasOriginDateIndex]?.projects.push(project);
        }
      }
    });
  }

  // Sort the main timeFrame array by date in descending order (newest first)
  timeFrame.sort((a, b) => b.date - a.date);

  // Sort each year's projects array by origin_date in descending order (newest first)
  timeFrame.forEach(timeFrameEntry => {
    timeFrameEntry.projects.sort((a, b) => new Date(b.origin_date) - new Date(a.origin_date));
  });

  return timeFrame;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gallery {
  margin: 65px 1rem;
}
</style>
