<template>
  <div class="header pb-md pt-xl">
    <h1 class="mb-sm mt-none">Blake Bless</h1>
    <a target="_blank" href="https://linktr.ee/blakeeatspaint">@blakeeatspaint</a>
    <div class="row mt-md full-width items-center justify-center">
      <div class="cursor-pointer" :class="selectedClass('gallery')" @click="setMode('gallery')">Gallery</div> 
      <div class="link-divider">|</div>
      <div class="cursor-pointer" :class="selectedClass('artist')" @click="setMode('artist')">The Artist</div>
      <div class="link-divider">|</div>
      <div class="cursor-pointer" :class="selectedClass('shows')" @click="setMode('shows')">Shows</div>
      <div class="link-divider">|</div>
      <div class="cursor-pointer" :class="selectedClass('contact')" @click="setMode('contact')">Contact Me</div>
    </div>
  </div>
  <Gallery v-if="mode == 'gallery'" @setMode="setMode" />
  <Artist v-if="mode == 'artist'" />
  <Shows v-if="mode == 'shows'" />
  <ContactForm v-if="mode == 'contact'" />
</template>

<script setup>
import { ref } from 'vue';
import Gallery from '@/components/Gallery.vue';
import ContactForm from '@/components/ContactForm.vue';
import Shows from '@/components/Shows.vue';
import Artist from '@/components/Artist.vue';

//Data
const mode = ref("gallery");

//Methods
const setMode = (newMode) => {
  mode.value = newMode;
}
const selectedClass = (selectedMode) => {
  return mode.value == selectedMode ? 'text-orange' : '';
}
</script>

<style lang="scss">
@import "@/styles/variables";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
}
.link-divider {
  margin: 0 10px;
}
</style>
