<template>
  <div v-if="!hasAdminAccess" class="row full-width justify-center">
    <form class="password-form">
      <div class="column full-width mb-xs mt-xl">
        <label class="mb-sm">Password</label>
        <input ref="admin-password" v-model="adminPassword" type="password" />
      </div>
      <div class="row justify-end">
        <button class="primary" @click="submitPassword">Submit</button>
      </div>
    </form>
  </div>
  <div v-if="hasAdminAccess" class="admin column full-width justify-center items-center">
    <div class="admin-container">
      <div class="admin-table my-lg" v-if="!formVisible">
        <div class="admin-table-header row justify-between items-center">
          <h2 class="row full-width">Current Projects</h2>
          <div class="row">
            <a @click="selectMode('all')" :class="{'active' : selectedMode.key == 'all'}">all</a>
            <a @click="selectMode('gallery')" :class="{'active' : selectedMode.key == 'gallery'}" class="mx-md">gallery</a>
            <a @click="selectMode('show')" :class="{'active' : selectedMode.key == 'show'}" class="mr-md">show</a>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="full-width">
            <tr>
              <th>
                Name
              </th>
              <th>
                Updated
              </th>
              <th>
                Type
              </th>
              <th>
                Hidden
              </th>
              <th>
                Origin Date
              </th>
              <th></th>
            </tr>
            <template v-if="filteredProjects.length">
              <tr v-for="project in filteredProjects" :key="project.id">
                <td>{{ project.name }}</td>
                <td>{{ formatDate(project.updated) }}</td>
                <td>{{ project.type }}</td>
                <td>{{ project.hidden }}</td>
                <td>{{ formatDate(project.origin_date) }}</td>
                <td style="text-align: right">
                  <a target="_blank" class="mr-md primary" @click="showEdit(project)">edit</a>
                  <a target="_blank" class="error" @click="showDelete(project)">delete</a>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td>No projects available</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="row justify-end">
          <button class="primary" target="_blank" @click="formVisible = true">Add Project</button>
        </div>
      </div>
      <div class="mt-lg" v-if="formVisible">
        <form class="full-width">
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Name</label>
            <input v-model="newProject.name" />
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Title</label>
            <input v-model="newProject.title" />
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Description</label>
            <input v-model="newProject.description" />
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">{{ !newProject.media_url ? "Upload media" : "Media" }}</label>
            <input id="mediaUploader" type="file" accept="image/*,mp4/*,mov/*" class="media-uploader cursor-pointer" v-if="!newProject.media_url" />
            <div id="mediaPreviewWindow" v-else>
              <div class="error cursor-pointer" @click="removeMedia(newProject)">X</div>
              <img :src="newProject.media_url" v-if="newProject.media_type == 'image'" />
              <video v-else-if="newProject.media_type == 'video'">
                <source :src="newProject.media_url" />
              </video>
            </div>
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Type</label>
            <select v-model="newProject.type">
              <option value="gallery">Gallery</option>
              <option value="show">Show</option>
            </select>
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Media Orientation</label>
            <select v-model="newProject.media_orientation">
              <option value="horizontal">Horizontal</option>
              <option value="vertical">Vertical</option>
            </select>
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Date of Origin</label>
            <input v-model="newProject.origin_date" placeholder="MM/DD/YYYY" />
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Hidden</label>
            <div class="hidden-toggle row items-center cursor-pointer">
              <span class="true" :class="{ 'selected' : newProject.hidden == true }" @click="newProject.hidden = true">Yes</span>
              <span class="false" :class="{ 'selected' : newProject.hidden == false }" @click="newProject.hidden = false">No</span>
            </div>
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Link</label>
            <input v-model="newProject.project_link" />
          </div>
          <div class="column full-width justify-start items-start mb-sm">
            <label class="mb-sm">Button for link?</label>
            <div class="hidden-toggle row items-center cursor-pointer">
              <span class="true" :class="{ 'selected' : hasButton == true }" @click="hasButton = true">Yes</span>
              <span class="false" :class="{ 'selected' : hasButton == false }" @click="hasButton = false">No</span>
            </div>
          </div>
          <div class="column full-width items-start mb-sm" v-if="hasButton">
            <label class="mb-sm">Button text</label>
            <input v-model="newProject.button_text" />
          </div>
          <div class="admin-actions row justify-end">
            <button class="mr-md" @click="cancelEdit">Cancel</button>
            <button class="primary" @click="saveProject" :disabled="isSaving">Save</button>
          </div>
        </form>
      </div>
    </div>
    <div class="confirm-delete" v-if="confirmDelete">
      <div>
        Are you sure you want to delete?
      </div>
      <div class="row justify-end">
        <button class="error mr-md" @click="deleteProject">Delete</button>
        <button @click="confirmDelete = false; projectToDelete = null">Cancel</button>
      </div>
    </div>
  </div>
  <div class="admin-saving column justify-center items-center" v-if="isSaving">
    <div class="mt-md">SAVING...</div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "@/composables/useToast";

const toast = useToast();
const store = useStore();
let selectedMode = ref({ label: "All", key: "all" });
let formVisible = ref(false);
let adminModes = ref([
  { label: "All", key: "all" },
  { label: "Gallery", key: "gallery" },
  { label: "Show", key: "show" }
]);
let newProject = ref({
  name: null,
  title: null,
  description: null,
  media_url: null,
  media_type: null,
  date_created: new Date().toISOString(),
  media_orientation: null,
  origin_date: null,
  type: null,
  hidden: false,
  button_text: null,
  project_link: "https://",
  outside_link: true
});
let confirmDelete = ref(false);
let projectToDelete = ref(null);
let hasButton = ref(false);
let adminPassword = ref(null);
let isSaving = ref(false);

//Lifecycle Hooks
onMounted(() => {
  getProjects();
});

//Computed
const hasAdminAccess = computed(() => store.state.hasAdminAccess);
const projects = computed(() => store.state.projects || []);
const filteredProjects = computed(() => {
  let filtered = projects.value;
  if(selectedMode.value?.key != 'all' && projects.value?.length){
    filtered = projects.value?.filter(a => a.type == selectedMode.value.key);
  }
  return filtered;
});

//Methods
const getProjects = async () => {
  await store.dispatch('getProjects');
};
const submitPassword = async (e) => {
  e.preventDefault();

  if(adminPassword.value){
    const passwordValid = await store.dispatch('submitPassword', adminPassword.value);
    if(!passwordValid){
      toast.newMessage({ type: 'negative', message: 'Password is incorrect', timeout: 2500 });
    }
  } else {
    toast.newMessage({ type: 'negative', message: 'Password is required', timeout: 2500 });
  }
};
const setNewProject = (project) => {
  if(!project){
    newProject.value = {
      name: null,
      title: null,
      description: null,
      media_url: null,
      media_type: null,
      date_created: new Date().toISOString(),
      media_orientation: null,
      origin_date: null,
      type: null,
      hidden: false,
      button_text: null,
      project_link: "https://",
      outside_link: true
    }
    hasButton.value = false;
  } else {
    newProject.value = {...project};
    hasButton.value = project.button_text ? true : false;
  }
  newProject.value.hidden = newProject.value.hidden != "false" && newProject.value.hidden != false ? true : false;
}
const selectMode = (modeKey) => {
  const foundMode = adminModes.value?.find(m => m.key === modeKey);
  if(modeKey && foundMode)
    selectedMode.value = foundMode;
}
const saveProject = async (e) => {
  try {
    isSaving.value = true;
    e?.preventDefault();

    const mediaUploader = document.getElementById("mediaUploader");

    // Check if there is an uploader
    if (mediaUploader) {
      const mediaFile = mediaUploader.files[0];

      // Check if there is a file in the uploader
      if (mediaFile) {
        const fileType = getFileType(mediaFile);

        // Store the media file in the S3 bucket
        const mediaUrl = await store.dispatch("uploadToS3", mediaFile);

        // Storing media URL on our project
        newProject.value.media_url = mediaUrl;
        newProject.value.media_type = fileType;
      }
    }

    // Update project details
    formVisible.value = false;
    newProject.value.updated = new Date().toISOString();

    if (newProject.value.button_text && !hasButton.value) {
      newProject.value.button_text = null;
    }

    // Dispatch save project action
    await store.dispatch('saveProject', newProject.value);

    // Reset form and state
    isSaving.value = false;
    setNewProject();
  } catch (e) {
    console.error("Error in saveProject: ", e);
    isSaving.value = false;
    // Handle error appropriately, e.g., show a user-friendly message
  }
};
const getFileType = (file) => {
  if (!file) {
    return null;  // No file was selected
  }

  const fileType = file.type;  // Get the MIME type of the file
  if (fileType.startsWith('image/')) {
    return 'image';
  } else if (fileType.startsWith('video/')) {
    return 'video';
  } else {
    return null;
  }
};
const cancelEdit = (e) => {
  e.preventDefault();
  setNewProject();
  formVisible.value = false;
}
const showEdit = (project) => {
  if(project) {
    setNewProject(project)
    formVisible.value = true;
  }
}
const showDelete = (project) => {
  projectToDelete.value = project;
  confirmDelete.value = true;
}
const deleteProject = async () => {
  if(projectToDelete.value){
    await removeMedia(projectToDelete.value);
    await store.dispatch('deleteProject', projectToDelete.value.bep_project_id);
    confirmDelete.value = false;
  }
}
const formatDate = (dateString) => {
  if(dateString){
    return new Date(dateString).toLocaleDateString();
  } else {
    return dateString;
  }
}
const removeMedia = async (project) => {
  if(project.media_url){
    const mediaId = project.media_url.split('.com/')[1];
    const mediaDeleted = await store.dispatch('deleteS3Media', mediaId);
    if (mediaDeleted) {
      project.media_url = null;
      project.media_type = null;
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
/* write SCSS here */
.admin-container {
  width: 70%;
}
.password-form {
  position: relative;
  width: 35%;
}
.admin {
  position: relative;
}
.hidden-toggle {
  color: #2c3e50 !important;
  border: 3px solid #2c3e50;
  background: none;
  margin-bottom: 20px;
  border-radius: 3px;
  font-size: 20px;
  width: fit-content;
  height: 45px;
  outline: none;
  padding: 0 0 0 1%;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  .true {
    border-right: 1px solid $primary;
    padding: 0 20px 0;
    color: gray;
    &.selected {
      color: $primary;
    }
  }
  .false {
    padding: 0 20px 0;
    color: gray;
    &.selected {
      color: $primary;
    }
  }
}
.admin-modes {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 0 10px;
  border-bottom: 1px wavy #000;
  padding: 10px 25px;
  color: #fff;
  border-bottom: 1px solid;
  transition: color ease-in-out .5s;
  width: 100%;
  .admin-mode {
    font-size: 12px;
    cursor: pointer;
    &.active-mode {
      color: $primary;
      transition: color ease-in-out .5s;
    }
  }
}
.admin-table {
  .admin-table-header {
    width: 100%;
  }
  .table-wrapper {
    width: 100%;
    overflow: auto;
  }
  table {
    max-height: 53vh;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td, th {
      text-align: left;
      padding: 8px;
    }
    th {
      border-bottom: 1px solid white;
    }
    tr:nth-child(even) {
      background-color: #51515161;
    }
  }
}
.confirm-delete {
  position: absolute;
  background: $default-toast;
  padding: 3%;
  top: 30%;
  border: 1px solid white;
}
.media-uploader {
  width: 100%;
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #444;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #555;
}
input[type=file]::file-selector-button {
  background: $primary;
  padding: 0 10px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
  margin-right: 20px;
  cursor: pointer;
}
#mediaPreviewWindow {
  width: 200px;
  position: relative;
  .error {
    position: absolute;
    right: 2px;
    z-index: 12;
  }
  img, video {
    width: 100%;
  }
}
.admin-saving {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 123;
  background: radial-gradient(#000000bd, transparent);
  color: white;
}
.admin-actions {
  margin-bottom: 100px;
}
</style>