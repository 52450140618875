<template>
  <div class="timeframe-wrapper" v-if="timeFrame.length">
    <div class="timeframe-section" v-for="tf in timeFrame" :key="tf.date">
      <div class="timeframe-date">{{ tf.date }}</div>
      <div class="timeframe-project my-lg" v-for="pr in tf.projects" :key="pr.date">
        <img class="project-img cursor-pointer" :class="pr.media_orientation" :src="pr.media_url" v-if="pr.media_url" @click="goToLink(pr.project_link)" />
        <div class="row justify-between" v-if="type == 'gallery'">
          <div>{{ pr.title }}</div>
          <span class="mx-md cursor-pointer error" @click="inquire">Inquire</span>
          <div>{{ pr.description }}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row justify-center">Nothing here</div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['inquire-clicked']);
const props = defineProps({
  type: {
    type: String,
    required: false
  },
  date: {
    type: String,
    required: false,
  },
  timeFrame: {
    type: Array,
    required: false
  }
});

//Methods
const goToLink = (link) => {
  if(link != "https://"){
    window.open(link, '_blank');
  } 
  return;
};
const inquire = () => {
  emit('inquire-clicked');
};
</script>
<style lang="scss">
.timeframe-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.timeframe-date {
  border-radius: 40px;
  position: sticky;
  top: 10px;
  width: 40px;
  padding: 25px 20px;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeframe-project {
  align-self: center;
}
.project-img {
  &.horizontal {
    height: 90vh;
    width: auto;
    max-height: 590px;
  }
  &.vertical {
    height: 95vh;
  }
}
</style>