<template>
  <div class="artist mx-md mt-lg">
    <div class="paragraph">
      Blake Bless, born in Oregon and now residing in Sacramento, is an experimental artist. Blake's work, characterized by a balance between abstract and figurative forms, embraces experimentation and authentic expression. Blake combines free-form marks with structured shapes to evoke raw emotions. Their intuitive process allows for natural evolution while maintaining intentionality. Blake's current projects, reflecting their journey into parenthood, focus on contrast, composition, and color, capturing a balance between control and chaos, and personal introspection and universal themes.
    </div>
    <img src="../../public/assets/me.png" />
  </div>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.artist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 65px auto;
  img {
    width: 100%;
    height: auto;
  }
}
.paragraph {
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 300;
  color: #333;
}
</style>
